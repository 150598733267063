<template>
    <el-date-picker
        :placeholder="placeholder"
        v-model="dt"
        :size="size"
        type="date"
        value-format="yyyy-MM-dd"
        :clearable="false"
        :picker-options="picker_options"
        popper-class="prb-f-datepicker_popper" />
</template>

<script>
import { date_shortcut } from '@/utils'
export default {
    props: {
        value: {
            required: true
        },
        placeholder: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: 'mini'
        }
    },

    data(){
        return {
            dt: this.value
        }
    },

    computed: {
        picker_options(){
            let $this = this
            let picker_shortcuts = []
            let shortcuts = [
                'yd', 
                'fdcw',
                'fdcm',
                'fdcq',
                'fdcy',
                'fbdcw',
                'fbdcm',
                'fbdcq',
                'fbdcy',
                'fbpw',
                'fbpm',
                'fbpq',
                'fbpy',
                'fbdpw',
                'fbdpm',
                'fbdpq',
                'fbdpy',
                'ldcw',
                'ldcm',
                'ldcq',
                'ldcy',
                'lbdcw',
                'lbdcm',
                'lbdcq',
                'lbdcy',
                'ldpw',
                'ldpm',
                'ldpq',
                'ldpy',
                'lbdpw',
                'lbdpm',
                'lbdpq',
                'lbdpy',
            ]
            shortcuts.forEach(shortcut => {
                let date_data = date_shortcut(shortcut)
                picker_shortcuts.push({
                    text: date_data.text,
                    onClick(picker) {
                        picker.$emit('pick', date_data.date);
                        $this.$emit('dt-text', [date_data.text, date_data.shortcut]);
                    }
                })
            })
            
            return {
                shortcuts: picker_shortcuts
            }
        }
    },

    watch: {
        dt(val) {
            this.$emit('input', this.dt);
        },
        value(){
            this.dt = this.value
        }
    }
}
</script>
